import { useState, useEffect } from "react";
import {
  Sidebar as ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { TbMoneybag } from "react-icons/tb";
import { CiFileOn, CiGlobe } from "react-icons/ci";
import { MdOutlinePayment } from "react-icons/md";
import { PiUsers } from "react-icons/pi";
import icon from "../assets/logo.png";
import { BsFilePost } from "react-icons/bs";
import black_logo from "../assets/images/vsign.png";

const Sidebar = () => {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [sidebarHeight, setSidebarHeight] = useState("100vh");
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const screenHeight = window.innerHeight;
    setSidebarHeight(`${screenHeight}px`);
  }, []);

  return (
    <>
      <aside
        id="default-sidebar"
        className="fixed top-0 left-0 z-40 w-[265px] h-screen transition-transform -translate-x-full sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div className="h-full flex flex-col justify-between px-1 py-4 overflow-y-auto">
          <div>
            <div className="mb-4 flex items-center">
              <img src={black_logo} alt="" srcSet="" className="mr-2" />
            </div>
            <ul className="space-y-2 font-medium mx-2">
              <li
                className={"my-4 mx-1 hover:bg-gray-100 rounded-lg"}
                style={{
                  background: location.pathname === "/" ? "" : "",
                }}
              >
                <Link
                  to="/dashboard"
                  className="flex items-center py-2 px-3 text-gray-900"
                >
                  <svg
                    aria-hidden="true"
                    className={
                      "w-5 h-5 transition duration-75 text-gray-500 group-hover:text-gray-900 "
                    }
                    style={{
                      color:
                        location.pathname === "/dashboard"
                          ? "#11c56a"
                          : "rgb(107 114 128)",
                    }}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                    <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                  </svg>
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap text-md`}
                    style={{
                      color:
                        location.pathname === "/dashboard" ? "#11c56a" : "",
                    }}
                  >
                    Dashboard
                  </span>
                </Link>
              </li>
              <li
                className={"my-2 mx-1 hover:bg-gray-100 rounded-lg"}
                style={{
                  background: location.pathname === "/dashboardblogs" ? "" : "",
                }}
              >
                <Link
                  to="dashboardblogs"
                  className="flex items-center py-2 px-3 text-gray-900"
                >
                  <BsFilePost
                    className={
                      "flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900"
                    }
                    color={`${
                      location.pathname === "/dashboardblogs"
                        ? "#11c56a"
                        : "rgb(107 114 128)"
                    }`}
                  />
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap text-md`}
                    style={{
                      color:
                        location.pathname === "/dashboardblogs"
                          ? "#11c56a"
                          : "",
                    }}
                  >
                    Blog Posts
                  </span>
                </Link>
              </li>
              <li
                className={"my-2 mx-1 hover:bg-gray-100 rounded-lg"}
                style={{
                  background:
                    location.pathname === "/adfarmersConnectmin" ? "" : "",
                }}
              >
                <Link
                  to="adfarmersConnectmin"
                  className="flex items-center py-2 px-3 text-gray-900"
                >
                  <BsFilePost
                    className={
                      "flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900"
                    }
                    color={`${
                      location.pathname === "/adfarmersConnectmin"
                        ? "#11c56a"
                        : "rgb(107 114 128)"
                    }`}
                  />
                  <span
                    className={`flex-1 ml-3 whitespace-nowrap text-md`}
                    style={{
                      color:
                        location.pathname === "/adfarmersConnectmin"
                          ? "#11c56a"
                          : "",
                    }}
                  >
                    Farmers Connect
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex justify-center items-center mt-4">
            <Typography variant="body2" color="textSecondary">
              Crafted by caesar127
            </Typography>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
