import { IoAdd } from "react-icons/io5";
import { GiCircle } from "react-icons/gi";
import { FiTriangle } from "react-icons/fi";
import Welcome from "../components/Welcome";
import logo from "../assets/images/logo.png";
import Features from "../components/Features";
import { BsCircleFill } from "react-icons/bs";
import farmhand from "../assets/images/home.jpg";
import React, { useState, useEffect } from "react";
import { Analytics } from "@vercel/analytics/react";
import heroImage from "../assets/images/metali.jpg";
import mobileapp from "../assets/images/mobile.png";
import app from "../assets/downloads/poultrypro.apk";
import vsign from "../assets/images/vsign_white.png";
import { Link } from "react-router-dom";

function Home() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      const nav = document.getElementById("navbar");
      if (nav) {
        if (scrolled) {
          nav.classList.add("scrolled");
        } else {
          nav.classList.remove("scrolled");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToEnhanceFarm = () => {
    const enhanceFarmSection = document.getElementById("enhanceFarm");
    if (enhanceFarmSection) {
      enhanceFarmSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="relative">
        <div
          className="hero-bg absolute top-0 left-0 w-full h-full"
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundSize: "cover",
            backgroundPositionX: "right",
          }}
        ></div>
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-60"></div>
        <nav id="navbar" className="navbar relative bg-transparent">
          <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16 sm:h-20">
              <div className="flex items-center">
                <img src={vsign} alt="" srcset="" className="h-14" />
              </div>
              <div className="hidden sm:block">
                <ul className="flex space-x-8 text-white font-[200]">
                  <li>
                    <Link to="/" className="block hover:text-green-500">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollToEnhanceFarm}
                      className="block hover:text-green-500"
                    >
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/blogposts"
                      className="block hover:text-green-500"
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/farmersconnect"
                      className="block hover:text-green-500"
                    >
                      Farmers Connect
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" className="block hover:text-green-500">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link to="/services" className="block hover:text-green-500">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sm:hidden">
                <button
                  onClick={toggleMenu}
                  type="button"
                  className="block text-gray-600 hover:text-green-500 focus:text-green-500 focus:outline-none"
                >
                  <svg
                    className={`h-6 w-6 ${isMenuOpen ? "hidden" : "block"}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <svg
                    className={`h-6 w-6 ${isMenuOpen ? "block" : "hidden"}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div
              className={`relative top-5 left-0 ${
                isMenuOpen ? "block" : "hidden"
              } bg-transparent w-full`}
            >
              <ul className="pt-1 pb-2 space-y-1">
                <li>
                  <Link
                    to="/"
                    className="block px-4 py-2 text-gray-200 font-[300] hover:text-green-500"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={scrollToEnhanceFarm}
                    className="block px-4 py-2 text-gray-200 font-[300] hover:text-green-500"
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blogposts"
                    className="block px-4 py-2 text-gray-200 font-[300] hover:text-green-500"
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="/farmersconnect"
                    className="block px-4 py-2 text-gray-200 font-[300] hover:text-green-500"
                  >
                    Farmers Connect
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className="block px-4 py-2 text-gray-200 font-[300] hover:text-green-500"
                  >
                    About us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    className="block px-4 py-2 text-gray-200 font-[300] hover:text-green-500"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="hero-section relative flex items-center justify-end h-[50vh] sm:h-[70vh]">
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-[-1]"></div>
          <div className="hero-content relative z-10 items-center sm:text-left text-right text-white mr-[10%] m-[2%] mt-[15%] ">
            <p className="text-5xl  sm:text-5xl sm:font-[500] mb-4">Wake up</p>
            <p className="text-5xl   sm:text-6xl font-normal mb-4">Farmer</p>
            <div className="flex justify-center sm:justify-start mb-4">
              <button
                onClick={scrollToEnhanceFarm}
                className="bg-white rounded-3xl px-5 py-2 flex items-center text-green-600 hover:bg-slate-200 transition duration-300 ease-in-out text-md"
              >
                Enhance your farm
                <img className="ml-2 w-6 h-6" src={logo} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Welcome />
      <Features />
      <div className="flex flex-col sm:flex-row items-center bg-slate-100 my-4">
        <div className="w-full md:w-6/12 order-2 sm:order-1">
          <img src={mobileapp} alt="" className="mx-auto sm:h-[650px]" />
          <FiTriangle
            size={24}
            className="text-green-500 left-10 sm:left-40 absolute animate-bounce"
            style={{ animationDuration: "4s", marginTop: "-15%" }}
          />
        </div>
        <div className="w-full pt-[50px] md:w-6/12 px-3 sm:px-1 order-1 sm:order-2">
          <IoAdd
            size={24}
            className="text-green-500 mt-[19%] sm:mt-0 right-[20%] sm:right-56 absolute animate-spin"
            style={{ animationDuration: "4s" }}
          />
          <h2 className="font-[500] text-xl sm:text-2xl sm:w-3/5 text-gray-900">
            Monitor and Control Your Poultry Farm with Our Mobile App
          </h2>
          <p
            className="text-[14px] sm:text-sm sm:w-4/5 my-4 text-gray-600"
            style={{ lineHeight: 1.8 }}
          >
            Transform the way you manage your poultry farm with our advanced
            mobile app. Stay in complete control by monitoring and controlling
            every aspect from the palm of your hand. Our app provides real-time
            updates on temperature, humidity, and feed levels, ensuring optimal
            conditions for your flock.
          </p>
          <p
            className="text-[14px] sm:text-sm sm:w-4/5 my-4 text-gray-600"
            style={{ lineHeight: 1.8 }}
          >
            Take advantage of automated feeding and watering systems that can be
            remotely controlled and adjusted as needed. Receive instant alerts
            for any abnormalities or emergencies, allowing for quick
            intervention.
          </p>
          <p
            className="text-[14px] sm:text-sm sm:w-4/5 my-4 text-gray-600"
            style={{ lineHeight: 1.8 }}
          >
            Track egg production, and manage inventory effortlessly. With our
            user-friendly interface and comprehensive features, running a
            successful and efficient poultry farm has never been easier. Embrace
            the power of mobile technology for poultry farm management today.
          </p>

          <a
            href={app}
            download
            className="my-4 bg-green-600 hover:bg-green-600 text-white text-xs sm:text-sm font-[600] py-3 px-6 rounded flex items-center shadow-lg shadow-slate-300 sm:w-[23%] w-[43%]"
          >
            Download app
          </a>
          <GiCircle
            size={24}
            className="right-[20%] sm:right-96 absolute animate-ping text-green-500"
            style={{ animationDuration: "4s" }}
          />
          <BsCircleFill
            size={24}
            className="right-[20%] sm:right-96 absolute opacity-50 text-green-500"
            style={{ animationDuration: "4s" }}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center my-4">
        <div className="w-full pt-[50px] md:w-6/12 px-3 sm:px-20 order-1 sm:order-1">
          <h2 className="font-[500] text-xl sm:text-2xl sm:w-4/5 text-gray-900">
            Revolutionize your farming with your mobile device
          </h2>
          <p
            className="text-[14px] sm:text-sm sm:w-90 my-4 text-gray-600"
            style={{ lineHeight: 1.8 }}
          >
            Revolutionize your farming operations with our cutting-edge mobile
            app designed to streamline the management of your entire farm.
            Experience unparalleled control as you effortlessly monitor and
            oversee every aspect of your poultry farm directly from your
            smartphone or tablet
          </p>
        </div>
        <div className="w-full md:w-6/12 order-2 sm:order-2">
          <img src={farmhand} alt="" className="mx-auto sm:h-[350px]" />
        </div>
      </div>
      <Analytics />
    </div>
  );
}

export default Home;
