import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  farmers: [], // List of farmers or farmers' posts
  farmerCount: 0, // Total number of farmers
  loading: false, // Loading state for API requests
  error: null, // Error message for any failed request
};

const farmersConnectSlice = createSlice({
  name: "farmersConnect",
  initialState,
  reducers: {
    setFarmers: (state, action) => {
      state.farmers = action.payload;
    },
    setFarmerCount: (state, action) => {
      state.farmerCount = action.payload.totalCount;
    },
    addFarmer: (state, action) => {
      state.farmers.push(action.payload);
    },
    updateFarmer: (state, action) => {
      const updatedFarmer = action.payload;
      const index = state.farmers.findIndex(
        (farmer) => farmer.id === updatedFarmer.id
      );
      if (index !== -1) {
        state.farmers[index] = updatedFarmer;
      }
    },
    removeFarmer: (state, action) => {
      const farmerId = action.payload;
      state.farmers = state.farmers.filter((farmer) => farmer.id !== farmerId);
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setFarmers,
  setFarmerCount,
  addFarmer,
  updateFarmer,
  removeFarmer,
  setLoading,
  setError,
} = farmersConnectSlice.actions;

export default farmersConnectSlice.reducer;
