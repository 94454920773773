import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import { useParams } from "react-router-dom";
import { IoPersonSharp } from "react-icons/io5";
import { Analytics } from "@vercel/analytics/react";
import { useSelector, useDispatch } from "react-redux";
import { setFarmers } from "../features/slice/farmersConnectSlice";
import { useFetchFarmersQuery } from "../features/api/farmersConnectApiSlice";
import EmptyAnimation from "../assets/lotties/Animation - 1709978147164.json";
import LoadingAnimation from "../assets/lotties/Animation - 1710081862551.json";

function FarmerView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useFetchFarmersQuery();
  const { farmers } = useSelector((state) => state.farmersConnect);
  const farmerPost = farmers.find((p) => p._id === id);

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (farmers.length === 0 && data) {
      dispatch(setFarmers(data));
    }
  }, [data, farmers, dispatch]);

  if (!farmerPost) {
    return isLoading ? (
      <div className="flex flex-col text-center items-center justify-center">
        <Lottie
          animationData={LoadingAnimation}
          style={{
            width: "250px",
            height: "250px",
            justifyContent: "center",
          }}
        />
        <p>Fetching Farmers</p>
      </div>
    ) : (
      <div>No Farmer data available</div>
    );
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const renderSection = (section, index) => {
    switch (section.type) {
      case "heading":
        return (
          <h2 key={index} className="text-lg md:text-xl font-semibold mb-2">
            {section.content}
          </h2>
        );
      case "paragraph":
        return (
          <div key={index} className="mb-2 md:mb-5">
            {section.content.split(/\r?\n/).map((paragraph, index) => (
              <p
                key={index}
                className="text-[0.95rem] text-gray-600 overflow-hidden"
                style={{ lineHeight: "1.75rem" }}
              >
                {paragraph}
              </p>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="px-8 sm:px-36 my-8">
      <h1 className="font-[700] text-green-600 text-2xl sm:text-4xl mb-2">
        {farmerPost.title}
      </h1>
      <div className="mb-5 flex items-center">
        <IoPersonSharp className="h-4 text-gray-400 mr-2" />
        <p className="text-gray-400 text-sm italic">{farmerPost.farmer}</p>
        <span className="mx-2">•</span>
        <p className="text-gray-400 text-sm italic">
          {formatDate(farmerPost.createdAt)}
        </p>
      </div>
      <p
        className="text-[0.95rem] mb-5 text-gray-600"
        style={{ lineHeight: "1.75rem" }}
      >
        {farmerPost.introduction}
      </p>

      {farmerPost.sections.map((section, index) =>
        renderSection(
          farmerPost.sections[farmerPost.sections.length - 1 - index],
          index
        )
      )}
      <div className="grid grid-cols-2 sm:grid-cols-6 gap-4">
        {farmerPost.gallery.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Gallery image ${index + 1}`}
            className="h-40 w-40 object-cover rounded-lg cursor-pointer"
            onClick={() => handleImageClick(image)}
          />
        ))}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center z-50"
          onClick={() => setModalOpen(false)}
        >
          <div className="relative">
            <img
              src={selectedImage}
              alt="Selected"
              className="max-h-[80vh] max-w-[90vw] rounded-lg"
            />
            <button
              className="absolute top-2 right-2 bg-white text-black rounded-full p-2 text-lg"
              onClick={(e) => {
                e.stopPropagation(); // Prevent closing modal on button click
                setModalOpen(false);
              }}
            >
              ✕
            </button>
          </div>
        </div>
      )}

      <Analytics />
    </div>
  );
}

export default FarmerView;
