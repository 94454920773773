import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { RxDotFilled } from "react-icons/rx";
import { FaUserCircle } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { Analytics } from "@vercel/analytics/react";
import mobileapp from "../assets/images/mobile.png";
import chills from "../assets/images/chills.jpg";
import app from "../assets/downloads/poultrypro.apk";
import vsign from "../assets/images/vsign_white.png";
import { useSelector, useDispatch } from "react-redux";
import heroImage from "../assets/images/woman-working-rural.jpg";
import { setFarmers } from "../features/slice/farmersConnectSlice";
import { useFetchFarmersQuery } from "../features/api/farmersConnectApiSlice";
import EmptyAnimation from "../assets/lotties/Animation - 1709978147164.json";
import LoadingAnimation from "../assets/lotties/Animation - 1710081862551.json";

function FarmersConnect() {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { data, isLoading, isError } = useFetchFarmersQuery();
  const { farmers } = useSelector((state) => state.farmersConnect);

  useEffect(() => {
    if (farmers.length === 0 && data) {
      dispatch(setFarmers(data));
    }
  }, [data, farmers, dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      const nav = document.getElementById("navbar");
      if (nav) {
        if (scrolled) {
          nav.classList.add("scrolled");
        } else {
          nav.classList.remove("scrolled");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToEnhanceFarm = () => {
    const enhanceFarmSection = document.getElementById("enhanceFarm");
    if (enhanceFarmSection) {
      enhanceFarmSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  return (
    <div>
      <div className="relative">
        <div
          className="hero-bg absolute top-0 left-0 w-full h-full"
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundSize: "cover",
            backgroundPositionX: "right",
          }}
        ></div>
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-60"></div>
        <nav id="navbar" className="navbar relative bg-transparent">
          <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16 sm:h-20">
              <div className="flex items-center">
                <img src={vsign} alt="" srcset="" className="h-14" />
              </div>
              <div className="hidden sm:block">
                <ul className="flex space-x-8 text-white font-[200]">
                  <li>
                    <Link to="/" className="block hover:text-green-500">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollToEnhanceFarm}
                      className="block hover:text-green-500"
                    >
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/blogposts"
                      className="block hover:text-green-500"
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/farmersconnect"
                      className="block hover:text-green-500"
                    >
                      Farmers Connect
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" className="block hover:text-green-500">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link to="/services" className="block hover:text-green-500">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="sm:hidden">
                <button
                  onClick={toggleMenu}
                  type="button"
                  className="block text-gray-600 hover:text-green-500 focus:text-green-500 focus:outline-none"
                >
                  <svg
                    className={`h-6 w-6 ${isMenuOpen ? "hidden" : "block"}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <svg
                    className={`h-6 w-6 ${isMenuOpen ? "block" : "hidden"}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div
              className={`relative top-5 left-0 ${
                isMenuOpen ? "block" : "hidden"
              } bg-transparent w-full`}
            >
              <ul className="pt-1 pb-2 space-y-1">
                <li>
                  <Link
                    to="/"
                    className="block px-4 py-2 text-gray-200 font-[300] hover:text-green-500"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={scrollToEnhanceFarm}
                    className="block px-4 py-2 text-gray-200 font-[300] hover:text-green-500"
                  >
                    Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blogposts"
                    className="block px-4 py-2 text-gray-200 font-[300] hover:text-green-500"
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="/farmersconnect"
                    className="block px-4 py-2 text-gray-200 font-[300] hover:text-green-500"
                  >
                    Farmers Connect
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className="block px-4 py-2 text-gray-200 font-[300] hover:text-green-500"
                  >
                    About us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    className="block px-4 py-2 text-gray-200 font-[300] hover:text-green-500"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="hero-section relative flex items-center justify-end h-[50vh] sm:h-[70vh]">
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-[-1]"></div>
          <div className="hero-content relative z-10 items-center sm:text-left text-left text-white mr-[10%] m-[2%] sm:mt-[8%] mt-[40%]">
            <p className="text-lg  sm:text-3xl sm:font-[500] mb-4">
              Inspiring Generations
            </p>
            <p className="text-xs sm:text-lg font-light mb-8 sm:w-1/2">
              Join a supportive community where farmers unite, learn, and grow.
              Whether seasoned or new, Farmers Connect is your place for shared
              wisdom, inspiration, and lasting bonds.
            </p>
            <div className="flex justify-start sm:justify-start mb-4">
              <button
                onClick={scrollToEnhanceFarm}
                className="bg-white rounded-3xl px-5 py-2 flex items-center text-green-600 hover:bg-slate-200 transition duration-300 ease-in-out text-md"
              >
                Connect
                <img className="ml-2 w-6 h-6" src={logo} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-[8%] my-8 sm:my-16">
        <div className="sm:flex justify-between items-center">
          <div className="mr-4">
            <h1 className="text-lg sm:text-3xl w-[90%]">
              Welcome to{" "}
              <span className="text-green-500"> Farmers Connect</span>
            </h1>
            <p className="my-5 sm:my-6 text-xs sm:text-sm sm:w-2/4">
              Together, we’re building a community where farmers inspire and
              support each other to grow and thrive. Stay tuned for tips,
              success stories, and opportunities to connect with like-minded
              farmers.
            </p>
          </div>
          <img
            src={chills}
            alt=""
            className="h-[26vh] sm:h-[30vh] sm:w-[50%] rounded-lg"
          />
        </div>
      </div>
      <p className="text-2xl font-medium mx-[8%] my-4 text-green-500">
        Farmers
      </p>
      {isLoading ? (
        <div className="flex flex-col text-center items-center justify-center">
          <Lottie
            animationData={LoadingAnimation}
            style={{
              width: "250px",
              height: "250px",
              justifyContent: "center",
            }}
          />
          <p>Fetching Farmers...</p>
        </div>
      ) : isError ? (
        <div className="flex flex-col text-center items-center justify-center">
          <p>Failed to load farmers.</p>
        </div>
      ) : (
        <>
          {farmers.length === 0 ? (
            <div className="flex flex-col text-center items-center justify-center">
              <Lottie
                animationData={EmptyAnimation}
                style={{
                  width: "250px",
                  height: "250px",
                  justifyContent: "center",
                }}
              />
              <p>No Farmers available</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-[5%] my-4 mx-[8%]">
              {farmers
                .slice()
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((farmerData, index) => {
                  const sectionWithImage = farmerData.sections.find(
                    (section) => section.type === "image"
                  );

                  return (
                    <Link
                      to={`/farmer/${farmerData._id}`}
                      className=" bg-slate-50 rounded-lg"
                    >
                      <div className="p-12 pb-0">
                        <img
                          src={sectionWithImage.content}
                          alt=""
                          className="rounded-[100%] h-48 w-48 border-4 border-[#41b78062] object-cover"
                        />
                      </div>
                      <div className="mt-8 text-white rounded-b-lg bg-gradient-to-r from-[#23bdb8] to-[#43e794] p-2 items-center flex flex-col">
                        <p className="text-[18px]">{farmerData.farmer}</p>
                        <span className="text-[13px]">
                          {formatDate(farmerData.createdAt)}
                        </span>
                      </div>
                    </Link>
                  );
                })}
            </div>
          )}
        </>
      )}
      <Analytics />
    </div>
  );
}

export default FarmersConnect;
