import { apiSlice } from "./apiSlice";

const FARMERS_CONNECT_URL = "api/farmersconnect"; // Adjust the base URL to match your API

export const farmersConnectApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchFarmers: builder.query({
      query: (page) => `${FARMERS_CONNECT_URL}/`, // Fetch a list of farmers (possibly paginated)
    }),
    fetchFarmerCount: builder.query({
      query: () => `${FARMERS_CONNECT_URL}/farmers/count`, // Get total count of farmers
    }),
    createFarmer: builder.mutation({
      query: (data) => ({
        url: `${FARMERS_CONNECT_URL}/`, // Endpoint to create a new farmer
        method: "POST",
        body: data,
      }),
    }),
    updateFarmer: builder.mutation({
      query: ({ id, data }) => ({
        url: `${FARMERS_CONNECT_URL}/${id}`, // Endpoint to update farmer info
        method: "PATCH",
        body: data,
      }),
    }),
    deleteFarmer: builder.mutation({
      query: (id) => ({
        url: `${FARMERS_CONNECT_URL}/${id}`, // Endpoint to delete a farmer
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useFetchFarmersQuery,
  useFetchFarmerCountQuery,
  useCreateFarmerMutation,
  useUpdateFarmerMutation,
  useDeleteFarmerMutation,
} = farmersConnectApiSlice;
