import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import {
  useFetchFarmersQuery,
  useDeleteFarmerMutation,
} from "../../features/api/farmersConnectApiSlice";
import { RxDotFilled } from "react-icons/rx";
import { FaUserCircle } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { Analytics } from "@vercel/analytics/react";
import { useSelector, useDispatch } from "react-redux";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { handleErrorToast, handleSuccessToast } from "../../util/toasts";
import EmptyAnimation from "../../assets/lotties/Animation - 1709978147164.json";
import LoadingAnimation from "../../assets/lotties/Animation - 1710081862551.json";
import {
  setFarmers,
  removeFarmer,
} from "../../features/slice/farmersConnectSlice";

function FarmersConnect() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { data, isLoading } = useFetchFarmersQuery();
  const [searchInput, setSearchInput] = useState("");
  const [selectedType, setSelectedType] = useState("all");
  const { farmers } = useSelector((state) => state.farmersConnect);
  const [selectedFarmer, setSelectedFarmer] = React.useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteFarmer, { isError, loading }] = useDeleteFarmerMutation();

  useEffect(() => {
    if (farmers.length === 0 && data) {
      dispatch(setFarmers(data));
    }
  }, [data, farmers, dispatch]);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleTypeFilterChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleMenuClick = (event, farmerData) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedFarmer(farmerData);
  };

  const handleDeletePost = () => {
    setAnchorEl(null);
    setOpen(false);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirmed = async () => {
    try {
      const res = await deleteFarmer(selectedFarmer._id);
      if (res.data.message === "Farmer deleted successfully") {
        dispatch(removeFarmer(selectedFarmer._id));
        setOpenDeleteDialog(false);
        setSelectedFarmer();
        handleSuccessToast("Operation success");
      } else {
        setSelectedFarmer();
        handleErrorToast("Operation failed");
      }
    } catch (error) {
      setSelectedFarmer();
      console.log(error);
      handleErrorToast("Operation failed");
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  return (
    <div
      className="p-4 rounded-md"
      style={{ height: "calc(100vh - 64px)", background: "rgb(255,255,255)" }}
    >
      <div className="flex justify-between items-center mb-6 p-2 rounded-lg">
        <div>
          <span className="text-[20px] font-[600] text-[#121926]">Blogs</span>
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-2">
              <li class="inline-flex items-center">
                <p className="flex items-center text-[14px] text-gray-800">
                  Dashboard
                </p>
              </li>
              <RxDotFilled className=" text-gray-400" />
              <li class="inline-flex items-center">
                <p className="flex items-center text-[14px] text-gray-800">
                  farmers
                </p>
              </li>
              <RxDotFilled className=" text-gray-400" />
              <li>
                <p className="inline-flex items-center text-[14px] text-gray-400">
                  List
                </p>
              </li>
            </ol>
          </nav>
        </div>
        <div className="flex justify-around">
          <Link
            to={`/farmersconnectform`}
            className="flex items-center text-gray-700 font-[500] text-sm rounded-lg px-2 py-2 m-1 bg-green-500"
          >
            <MdAdd className="text-white" size={24} />
            <span class="ml-2 text-white">New Farmer</span>
          </Link>
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-col text-center items-center justify-center">
          <Lottie
            animationData={LoadingAnimation}
            style={{
              width: "250px",
              height: "250px",
              justifyContent: "center",
            }}
          />
          <p>Fetching Posts</p>
        </div>
      ) : (
        <>
          {farmers.length === 0 ? (
            <div className="flex flex-col text-center items-center justify-center">
              <Lottie
                animationData={EmptyAnimation}
                style={{
                  width: "250px",
                  height: "250px",
                  justifyContent: "center",
                }}
              />
              <p>No Posts available</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {farmers.map((farmersData, index) => {
                const sectionWithImage = farmersData.sections.find(
                  (section) => section.type === "image"
                );

                return (
                  <div
                    key={index}
                    className="rounded-xl shadow-md p-2 bg-white"
                  >
                    {sectionWithImage ? (
                      <img
                        src={sectionWithImage.content}
                        alt={farmersData.title}
                        className="h-32 w-[100%] rounded-lg object-cover mb-2"
                      />
                    ) : (
                      <div className="h-32 w-[100%] bg-gray-300 rounded-lg mb-2"></div>
                    )}
                    <div>
                      <h1 className="text-base font-[500] mb-2 line-clamp-1">
                        {farmersData.title}
                      </h1>
                      <p
                        className="text-gray-600 text-sm font line-clamp-2 mb-4"
                        style={{ lineHeight: "1.2rem" }}
                      >
                        {farmersData.introduction}
                      </p>
                      <div className="flex align-middle items-center">
                        <FaUserCircle
                          className="text-gray-300 mr-1"
                          size={18}
                        />
                        <p className="text-gray-800 font-[500] text-xs">
                          {farmersData.farmer}
                        </p>
                        <RxDotFilled className=" text-gray-800" size={12} />
                        <p className="text-gray-800 font-[500] text-xs">
                          {formatDate(farmersData.createdAt)}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Post?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FarmersConnect;
