import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  useCreateFarmerMutation,
  useUpdateFarmerMutation,
} from "../../features/api/farmersConnectApiSlice";
import { storage } from "../../config/firebaseConfig";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  addFarmer,
  updateFarmer,
} from "../../features/slice/farmersConnectSlice";
import { MdOutlineRemoveCircleOutline } from "react-icons/md";
import { handleErrorToast, handleSuccessToast } from "../../util/toasts";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ImageUploader from "../../components/ImageUploader";
import imageCompression from "browser-image-compression";

const SectionTypes = {
  HEADING: "heading",
  SUBHEADER: "subheader",
  PARAGRAPH: "paragraph",
  IMAGE: "image",
};

function FarmersConnectForm({ post: farmersConnect }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imageUpload, setImageUpload] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    farmer: "",
    title: "",
    introduction: "",
    content: "",
    tags: "",
    sections: [],
    images: [],
  });

  const { farmer, title, introduction, content, tags, sections } = formData;

  const [createFarmer, { loading: isCreating }] = useCreateFarmerMutation();
  const [updateFarmer, { loading: isUpdating }] = useUpdateFarmerMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSectionChange = (index, value) => {
    const newSections = [...sections];
    newSections[index].content = value;
    setFormData((prevData) => ({
      ...prevData,
      sections: newSections,
    }));
  };

  const handleAddSection = (type) => {
    setFormData((prevData) => ({
      ...prevData,
      sections: [{ type, content: "" }, ...prevData.sections],
    }));
  };

  const handleRemoveSection = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      sections: sections.filter((_, i) => i !== index),
    }));
  };

  const handleImageChange = (index, image) => {
    const newSections = [...sections];
    newSections[index].content = image;
    setImageUpload(URL.createObjectURL(image));
    setFormData((prevData) => ({
      ...prevData,
      sections: newSections,
    }));
  };

  const generateUniqueFilename = (filename) => {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(2, 8);
    const extension = filename.split(".").pop();
    return `${timestamp}-${randomString}.${extension}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    console.log("Form data before submission:", formData);

    const { farmer, title, introduction, content, images, tags, sections } =
      formData;

    try {
      const updatedSections = await Promise.all(
        sections.map(async (section) => {
          if (section.type === "image" && section.content) {
            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1024,
              useWebWorker: true,
            };
            const compressedFile = await imageCompression(
              section.content,
              options
            );
            const imageRef = ref(
              storage,
              `images/${generateUniqueFilename(compressedFile.name)}`
            );
            await uploadBytes(imageRef, compressedFile);
            const downloadUrl = await getDownloadURL(imageRef);
            console.log("Image URL:", downloadUrl);
            return { ...section, content: downloadUrl };
          }
          return section;
        })
      );
      console.log(updatedSections);

      const body = {
        farmer,
        title,
        introduction,
        content,
        tags,
        gallery: images,
        sections: updatedSections,
      };

      console.log("Submitting with body:", body);
      const result = await createFarmer(body).unwrap();
      dispatch(addFarmer(result));
      handleSuccessToast("Created successfully");
    } catch (error) {
      console.error("Error adding post: ", error);
      handleErrorToast("Failed to submit post");
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderSection = (section, index) => {
    switch (section.type) {
      case SectionTypes.HEADING:
        return (
          <TextField
            key={index}
            label="Heading"
            variant="outlined"
            size="small"
            value={section.content}
            onChange={(e) => handleSectionChange(index, e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleRemoveSection(index)}>
                    <MdOutlineRemoveCircleOutline />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        );
      case SectionTypes.SUBHEADER:
        return (
          <TextField
            key={index}
            label="Subheader"
            variant="outlined"
            size="small"
            value={section.content}
            onChange={(e) => handleSectionChange(index, e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleRemoveSection(index)}>
                    <MdOutlineRemoveCircleOutline />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        );
      case SectionTypes.IMAGE:
        return (
          <ImageUploader
            key={index}
            picture={imageUpload}
            onPictureChange={(image) => handleImageChange(index, image)}
          />
        );
      case SectionTypes.PARAGRAPH:
        return (
          <TextField
            key={index}
            label="Paragraph"
            variant="outlined"
            size="small"
            multiline
            rows={4}
            value={section.content}
            onChange={(e) => handleSectionChange(index, e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleRemoveSection(index)}>
                    <MdOutlineRemoveCircleOutline />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        );
      default:
        return null;
    }
  };

  // Add this in FarmersConnectForm component
  const handleImagesChange = async (event) => {
    const files = Array.from(event.target.files); // Convert FileList to Array
    const uploadedImages = [];

    for (const file of files) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);

      const imageRef = ref(
        storage,
        `images/${generateUniqueFilename(file.name)}`
      );
      await uploadBytes(imageRef, compressedFile);
      const downloadUrl = await getDownloadURL(imageRef);
      uploadedImages.push(downloadUrl);
    }

    setFormData((prevData) => ({
      ...prevData,
      images: [...prevData.images, ...uploadedImages],
    }));
  };

  const handleRemoveImage = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      images: prevData.images.filter((_, i) => i !== index),
    }));
  };

  return (
    <div
      className="p-4 rounded-md"
      style={{ height: "calc(100vh - 64px)", background: "rgb(255,255,255)" }}
    >
      <h1 className="text-xl font-[600] mt-2 mb-2 px-6">Blog Post Form</h1>
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="flex px-5"
      >
        <Box
          display="flex"
          sx={{
            "& .MuiTextField-root": { m: 1.5, width: "30ch" },
            "& .btn": { m: 1.5, width: "39ch" },
            "& .box": { m: 1.5, width: "27ch" },
          }}
        >
          <Box>
            <Box>
              <TextField
                label="farmer"
                variant="outlined"
                size="small"
                name="farmer"
                value={farmer}
                onChange={handleChange}
                required
                sx={{ mb: 2 }}
              />
              <TextField
                label="Title"
                variant="outlined"
                size="small"
                name="title"
                value={title}
                onChange={handleChange}
                required
                sx={{ mb: 2 }}
              />
            </Box>

            <Box>
              <TextField
                label="Content"
                variant="outlined"
                size="small"
                name="content"
                value={content}
                onChange={handleChange}
                required
                sx={{ mb: 2 }}
              />
              <TextField
                label="Tags"
                variant="outlined"
                size="small"
                name="tags"
                value={tags}
                onChange={handleChange}
                required
                sx={{ mb: 2 }}
              />
            </Box>
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1.5, width: "62ch" },
              }}
            >
              <TextField
                label="Introduction"
                variant="outlined"
                size="small"
                name="introduction"
                multiline
                rows={4}
                value={introduction}
                onChange={handleChange}
                width="full"
              />
              <Box sx={{ mb: 2 }}>
                <label htmlFor="images-upload">
                  <input
                    type="file"
                    id="images-upload"
                    accept="image/*"
                    multiple
                    onChange={handleImagesChange}
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="outlined"
                    component="span"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    Upload Images
                  </Button>
                </label>

                <Box display="flex" flexWrap="wrap" gap={2}>
                  {formData.images.map((url, index) => (
                    <Box key={index} position="relative" width="100px">
                      <img
                        src={url}
                        alt={`uploaded-${index}`}
                        style={{ width: "100%" }}
                      />
                      <IconButton
                        size="small"
                        onClick={() => handleRemoveImage(index)}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          background: "rgba(255, 255, 255, 0.8)",
                        }}
                      >
                        <MdOutlineRemoveCircleOutline />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Box>

              <div className="grid grid-cols-2 gap-x-8 gap-y-5 m-3">
                <button
                  type="button"
                  variant="contained"
                  onClick={() => handleAddSection(SectionTypes.HEADING)}
                  className="border border-green-500 text-green-500 rounded-lg w-full py-2"
                >
                  Add Heading
                </button>
                <button
                  type="button"
                  variant="contained"
                  onClick={() => handleAddSection(SectionTypes.SUBHEADER)}
                  className="border border-green-500 text-green-500 rounded-lg w-full py-2"
                >
                  Add Subheader
                </button>
                <button
                  type="button"
                  variant="contained"
                  onClick={() => handleAddSection(SectionTypes.IMAGE)}
                  className="border border-green-500 text-green-500 rounded-lg w-full py-2"
                >
                  Add Image
                </button>
                <button
                  type="button"
                  variant="contained"
                  onClick={() => handleAddSection(SectionTypes.PARAGRAPH)}
                  className="border border-green-500 text-green-500 rounded-lg w-full py-2"
                >
                  Add Paragraph
                </button>
              </div>
            </Box>
            <Box display="flex" justifyContent="center">
              <Button
                type="submit"
                className="btn"
                variant="contained"
                disabled={isSubmitting}
                style={{ background: "#11c56a" }}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
            <Box display="flex" justifyContent="space-around" mt={2}></Box>
          </Box>
          <Box marginLeft={1}>
            <h1 className="text-lg font-[500] mt-1">Sections</h1>
            <Box
              sx={{
                maxHeight: 450,
                borderRadius: "8px",
                overflowY: "auto",
                "&::-webkit-scrollbar": { width: "3px" },
                "&::-webkit-scrollbar-thumb": {
                  background: "#DEDEDE",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-track": { background: "transparent" },
                "& .MuiTextField-root": { m: 1.5, width: "33ch" },
                "& .btn": { m: 1.5, width: "39ch" },
                "& .box": { m: 1.5, width: "33ch" },
              }}
            >
              {sections.map((section, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  {renderSection(section, index)}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </form>
    </div>
  );
}

export default FarmersConnectForm;
